import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import filters from './filters';
import moment from 'moment'

import './bootstrap';
require('@/bootstrap');

import ws from './services/login';

Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

Vue.use(filters);
Vue.use(moment);

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

router.beforeEach(async (to, from, next) => {
  if(to.meta.is_public){
    next(); return;
  }
  else{
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    }
    else{
      try {
          let response = await ws.validate({token: localStorage.getItem("token")});
          
          if(response.type == "success"){
            let data = response.data;

            if(to.meta.is_admin && data.role.slug == "normal"){
              next({
                path: "/app",
                params: { nextUrl: to.fullPath },
              });
            }
            else{
              next();  
            }
          }
          else{
            next({
              path: "/login",
              params: { nextUrl: to.fullPath },
            });
          }
      }
      catch (err) {
        localStorage.removeItem("account");
        localStorage.removeItem("token");

        console.log(err);
      }
    }
  }
});

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  i18n,
  template: '<App/>',
  components: { 
    App
  }
})
